$(function () {
  const $win = $(window);
  let winW = $win.width();
  let winH = $win.height();
  const breakPoint_sp = 641;
  let el = '';
  let modalID = '';
  let modalTitle = '';
  let modalText = '';
  var scrollPosition;
  var click = true;

  // modal
  // open
  $('.js_modalOpen a').on('click', function (e) {
    if($(window).scrollTop()==0){
    } else {
      scrollPosition = $(window).scrollTop();
    }
    // e.preventDefault();
    document.body.style.position = 'fixed';
    document.body.style.top = '-${window.scrollY}px';
    winW = $win.width();
    $('.home_modal').fadeIn();
    if (scrollPosition == 0) {} else {
      $('body').addClass('fixed').css({'top': -scrollPosition});
    }
    if(winW > breakPoint_sp){
      // pc
      var target = $(this).parent();
      changeModalContent(target);
    }else{
      // sp
      var elem = document.querySelector('#js_modalAppend');
      var flkty = new Flickity( elem, {
        // options
        prevNextButtons: false,
        hash: true
      });
    }
  });
  // close
  $('.js_modalClose').on('click', () => {
    const top = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    $('body').removeClass('is_modalOpen').css('top','');
    $('.home_modal').fadeOut();
    window.scrollTo( 0 , scrollPosition );
  });

  // modal info
  const modalArray = {
    id: [
      'Fruits',
      'Hospital',
      'Water',
      'Skin',
      'Alcohol',
      'Brain',
      'Tree',
      'Sleep',
    ],
    title: [
      '最強の抗酸化物質',
      '風邪をひきにくく',
      'ハリのある肌に',
      'シミを抑えて美白へ',
      'お酒やタバコの後に',
      'ストレスと戦う人に',
      'つらい花粉症に',
      '快眠のお手伝い',
    ],
    text: [
      '酸素の一部は体内で活性酸素となって細菌やウイルスを撃退しますが、増えすぎると正常なDNAや細胞をも攻撃し、老化やガン、動脈硬化、脳卒中、糖尿病などの原因に。ビタミンCにはそんな増えすぎた活性酸素を無害化する抗酸化作用があり、からだを酸化ストレスから守ってくれるのです。',
      'ビタミンCは私たちの免疫機能を高める働きがあり、風邪などの不調を起こしにくいからだをつくってくれます。また体内に侵入した細菌やウイルスを攻撃するインターフェロンの産生を高めます。',
      'シワやたるみの原因となる活性酸素を無害化するだけでなく、ビタミンCにはコラーゲンの生成を促す働きがあり、皮膚に弾力を与え、毛穴の引き締め効果も期待できます。',
      'ビタミンCはメラニンの生成を抑え、シミやくすみの発生を防いでくれます。さらに抗酸化作用により、紫外線で黒く酸化したメラニンを無色化、つまりお肌の美白効果をもたらしてくれるのです。',
      'アルコールを分解するときやタバコの煙を吸ったときには活性酸素が発生するので、それを無害化するためにビタミンCが使われています。特にタバコは副流煙を吸っただけでも多量の活性酸素が発生し、たくさんのビタミンCが消費されるのです。',
      'ビタミンCはストレスを軽減するホルモン（＝アドレナリン）の生成に使われ、ストレス時には通常時の３〜８倍ものビタミンCが消費されます。そのためビタミンCが不足すると、ストレスへの抵抗力が低下してしまうのです。',
      'ビタミンCにはくしゃみや鼻水といった花粉症の症状を引き起こすヒスタミンの分泌を抑える作用があります。そのため花粉症の軽減も期待できるのです。',
      'ビタミンCは安眠を妨げるコルチゾールというホルモンの分泌を抑えることが知られています。また十分量の血中ビタミンC濃度を維持することで、寝ている間の疲労回復も期待でき、すっきりとした目覚めにつながります。',
    ],
  };

  // modal template
  if(winW > breakPoint_sp){
    // PC
    el += '<article class="home_modalContent">';
    el += '<h4 class="home_modalTitle"></h4>';
    el += '<div class="home_modalText"><p></p></div></article>';
    $('#js_modalAppend').append(el);
  }else{
    // SP
    for (let i = 0; i < 8; i++) {
      modalID = modalArray.id[i];
      modalTitle = modalArray.title[i];
      modalText = modalArray.text[i];
      el += '<article class="home_modalContent" id="vitaminList' + modalID + '">';
      el += '<h4 class="home_modalTitle">' + modalTitle + '</h4>';
      el += '<div class="home_modalText"><p>' + modalText + '</p></div></article>';
    }
    $('#js_modalAppend').append(el);
  }

  // グロナビ
  const $headerNavi = $('.ly_headerNavi');
  $('#jsHeaderX').on('click', function(){
    const status = $(this).attr('class').split(' ');
    if(status.indexOf('is_default') > 0){
      // 初回
      $(this).removeClass('is_default').addClass('is_open');
      $headerNavi.fadeIn().css('display', 'flex');
    }else if(status.indexOf('is_open') > 0){
      // クローズ
      $(this).removeClass('is_open').addClass('is_close');
      $headerNavi.fadeOut();
    }else if(status.indexOf('is_close') > 0){
      // オープン
      $(this).removeClass('is_close').addClass('is_open');
      $headerNavi.fadeIn().css('display', 'flex');
    }
  });

  // グロナビ内アコーディオン
  const $headerListSP = $('.ly_headerListSP');
  $('#jsHeaderSeriesC').on('click', function(){
    winW = $win.width();
    if(winW < breakPoint_sp){
      const status = $(this).attr('class').split(' ');
      if(status.indexOf('is_default') > 0){
        // 初回
        $(this).removeClass('is_default').addClass('is_open');
        $headerListSP.slideToggle();
      }else if(status.indexOf('is_open') > 0){
        // クローズ
        $(this).removeClass('is_open').addClass('is_close');
        $headerListSP.slideToggle();
      }else if(status.indexOf('is_close') > 0){
        // オープン
        $(this).removeClass('is_close').addClass('is_open');
        $headerListSP.slideToggle();
      }
    }
  });

  $('.js_anchorSP').on('click', function(){
    winW = $win.width();
    if(winW < breakPoint_sp){
      $('#jsHeaderX').click();
    }
  });

  // modal内メニューの切り替え

  function changeModalContent(element) {
    $('.home_modalList').removeClass('is_select');
    var selectClass = element.find('a').attr('class');
    switch (selectClass) {
      case 'home_vitaminList__Fruits':
        $('.home_modalList .home_vitaminList__Fruits').parent().addClass('is_select');
        modalTitle = modalArray.title[0];
        modalText = modalArray.text[0];
        break;
      case 'home_vitaminList__Hospital':
        $('.home_modalList .home_vitaminList__Hospital').parent().addClass('is_select');
        modalTitle = modalArray.title[1];
        modalText = modalArray.text[1];
        break;
      case 'home_vitaminList__Water':
        $('.home_modalList .home_vitaminList__Water').parent().addClass('is_select');
        modalTitle = modalArray.title[2];
        modalText = modalArray.text[2];
        break;
      case 'home_vitaminList__Skin':
        $('.home_modalList .home_vitaminList__Skin').parent().addClass('is_select');
        modalTitle = modalArray.title[3];
        modalText = modalArray.text[3];
        break;
      case 'home_vitaminList__Alcohol':
        $('.home_modalList .home_vitaminList__Alcohol').parent().addClass('is_select');
        modalTitle = modalArray.title[4];
        modalText = modalArray.text[4];
        break;
      case 'home_vitaminList__Brain':
        $('.home_modalList .home_vitaminList__Brain').parent().addClass('is_select');
        modalTitle = modalArray.title[5];
        modalText = modalArray.text[5];
        break;
      case 'home_vitaminList__Tree':
        $('.home_modalList .home_vitaminList__Tree').parent().addClass('is_select');
        modalTitle = modalArray.title[6];
        modalText = modalArray.text[6];
        break;
      case 'home_vitaminList__Sleep':
        $('.home_modalList .home_vitaminList__Sleep').parent().addClass('is_select');
        modalTitle = modalArray.title[7];
        modalText = modalArray.text[7];
        break;
    }
    $('.home_modalTitle').text(modalTitle);
    $('.home_modalText p').text(modalText);
  }

  // smooth scroll
  $('a[href^="#"]').not('.home_vitaminList a').on('click', function () {
    let speed = 500;
    let href = $(this).attr('href');
    let target = $(href == '#' || href == '' ? 'html' : href);
    let position = target.offset().top;
    $('html, body').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  const $jsAaVideo = $('#js_aaVideo');
  $('#js_aaVideoThumb').on('click', function(){
    $(this).fadeOut();
    $jsAaVideo[0].play();
  });
});

// header固定
ScrollTrigger.create({
  start: 'top -40',
  end: 'bottom top',
  toggleClass: { className: 'js_header__scrolled', targets: '.js_header' },
});

ScrollTrigger.create({
  trigger: '#displayBanner',
  start: 'top 76%',
  end: 'top bottom',
  endTrigger: '#footer',
  toggleClass: { className: 'is_on', targets: '.js_banner' },
});
